<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col v-if="!isAtama" xl="7" lg="7" cols="12">
          <b-card-actions
              class="list-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12" style="place-items: center;display: flex;justify-content: space-between;">
                  <h4 class="text-white d-inline-block card-title">Depo Komisyon Şeması</h4>
                  <b-button @click="isAtama = true" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            class="btn-icon"
                  >
                    Atama Yap
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5">
              <thead>
              <tr>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Adı
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Unvan
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Pozisyon
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Evrak
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                />
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item ,i) in semaListesi" :key="i">
                <td style="width: 30%">
                  {{ getSemaUyeInfo(item.uyeID).adSoyad }}
                </td>
                <td style="width: 20%">
                  {{ getSemaUyeInfo(item.uyeID).unvan }}
                </td>
                <td style="width: 20%">
                  {{ getPozisyonAdi(item.pozisyon) }}
                </td>
                <td style="width: 30%">
                  {{ getEvrakTuru(item.evrakTuru) }}
                </td>
                <td style="width: 10%">
                  <b-row class="d-flex justify-content-end">
                    <b-button
                        v-if="false"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip="'Düzenle'"
                        size="sm"
                        @click="updateStepOne(item)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon rounded-circle mr-50"
                        size="sm"
                        v-b-tooltip.right="'Sil'"
                        @click="removeSema(item.semaID)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col v-else xl="7" lg="7" cols="12">
          <b-card-actions
              class="list-card"
              ref="second-card"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row"
                   :style="{backgroundColor: !edit ? $store.getters.COLORS_GET.cardTitle : 'orange'}"
              >
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">
                    Üye Ataması
                  </h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-5">
              <b-row>
                <b-col cols="12" class="mt-sm-2">
                  <b-form-group
                      label="Üye Seçiniz"
                      label-for="vi-first-name"
                  >
                    <v-select
                        v-model="atamaData.uye"
                        label="adSoyad"
                        :options="uyeler"
                        no-options-text="Seçenek Yok"
                        :clearable="false"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="mt-sm-2">
                  <b-form-group
                      label="Pozisyon"
                      label-for="vi-first-name"
                  >
                    <v-select
                        v-model="atamaData.pozisyon"
                        label="name"
                        :options="pozisyonOptions"
                        :clearable="false"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="mt-sm-2">
                  <b-form-group
                      label="Evrak Türü"
                      label-for="vi-first-name"
                  >
                    <v-select
                        v-model="atamaData.evrakTuru"
                        label="name"
                        :options="evrakTuruOptions"
                        :clearable="false"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4" class="text-right">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-50"
                      :disabled="!atamaData.uye"
                      @click="atamaSave()"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <font-awesome-icon :icon="'fa-solid' + !edit ? 'fa-floppy-disk':'fa-user-pen'" class="mr-50"/>
                    Kaydet
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="danger"
                      @click="isAtama = false"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
        <b-col v-if="!edit && !isSave" xl="5" lg="5" cols="12">
          <b-card-actions
              class="list-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12" style="place-items: center;display: flex;justify-content: space-between;">
                  <h4 class="text-white d-inline-block card-title">Üye Listesi</h4>
                  <b-button @click="isSave = true" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            class="btn-icon"
                  >
                    Üye Ekle
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5">
              <thead>
              <tr>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Adı
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Unvan
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                />
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item ,i) in uyeler" :key="i">
                <td style="width: 50%">
                  {{ item.adSoyad }}
                </td>
                <td style="width: 20%">
                  {{ item.unvan }}
                </td>
                <td style="width: 10%">
                  <b-row class="d-flex justify-content-end">
                    <b-button
                        v-if="false"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip="'Düzenle'"
                        size="sm"
                        @click="updateStepOne(item)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon rounded-circle mr-50"
                        size="sm"
                        v-b-tooltip.right="'Sil'"
                        @click="remove(item.uyeID)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col v-if="edit" xl="5" lg="5" cols="12">
          <b-card-actions
              class="list-card"
              ref="second-card"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row"
                   :style="{backgroundColor: !edit ? $store.getters.COLORS_GET.cardTitle : 'orange'}"
              >
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">
                    {{ oldName + ' Adlı Üye Düzenleniyor' }}
                  </h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-5">
              <b-row>
                <b-col cols="12" class="mt-sm-2">
                  <b-form-group
                      label="Adı Soyadı"
                      label-for="vi-first-name"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="vi-first-name"
                          autocomplete="off"
                          v-model="name"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Ünvan"
                      label-for="unvan"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-feather"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="vi-first-name"
                          autocomplete="off"
                          v-model="unvan"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4" class="text-right">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-50"
                      @click="updateStepSecond()"
                      :disabled="!name || !unvan"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <font-awesome-icon :icon="'fa-solid' + !edit ? 'fa-floppy-disk':'fa-user-pen'" class="mr-50"/>
                    Güncelle
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="danger"
                      @click="reset()"
                      v-if="edit"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
        <b-col v-if="isSave" xl="5" lg="5" cols="12">
          <b-card-actions
              class="list-card"
              ref="second-card"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row"
                   :style="{backgroundColor: !edit ? $store.getters.COLORS_GET.cardTitle : 'orange'}"
              >
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">
                    Üye Kayıt
                  </h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-5">
              <b-row>
                <b-col cols="12" class="mt-sm-2">
                  <b-form-group
                      label="Adı Soyadı"
                      label-for="vi-first-name"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="vi-first-name"
                          autocomplete="off"
                          v-model="name"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Ünvan"
                      label-for="unvan"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-feather"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="vi-first-name"
                          autocomplete="off"
                          v-model="unvan"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4" class="text-right">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-50"
                      @click="save()"
                      :disabled="!name || !unvan"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-floppy-disk" class="mr-50"/>
                    Kaydet
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="danger"
                      @click="isSave = false,reset()"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BForm,
  BTab,
  BTabs,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormTextarea,
  BCard,
  BCardText,
  BContainer,
  BFormSelect,
  BFormInvalidFeedback,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem, BInputGroupPrepend, BPagination,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    BForm,
    vSelect,
    BFormInvalidFeedback,
    BPagination,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormTextarea,
    flatPickr,
    BCard,
    BCardText,
    BContainer,
    BFormSelect,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      isSave: false,
      isAtama: false,
      atamaData: {
        uye: null,
        pozisyon: null,
        evrakTuru: null,
      },
      pozisyonOptions: [
        {
          id: 0,
          name: 'Üye'
        },
        {
          id: 1,
          name: 'Başkan'
        },
        {
          id: 2,
          name: 'Harcama Yetkilisi'
        },
      ],
      evrakTuruOptions: [
        {
          id: 0,
          name: 'Harcama ve Sipariş Fiş Raporu'
        },
        {
          id: 1,
          name: 'İç Dağıtım ve Çıkış Raporu'
        },
      ],
      uyeID: null,
      unvan: null,
      name: null,
      edit: false,
      oldName: null,
    }
  },
  computed: {
    semaListesi() {
      return Object.values(store.getters.DEPO_KOMISYON_SEMA_GET)
    },
    uyeler() {
      return Object.values(store.getters.DEPO_UYELER_GET)
    },
  },
  methods: {
    getPozisyonAdi(a) {
      if (a == 0) return 'Üye'
      if (a == 1) return 'Başkan'
      if (a == 2) return 'Harcama Yetkilisi'
      return ''
    },
    getEvrakTuru(a) {
      if (a == 0) return 'Harcama ve Sipariş Fiş Raporu'
      if (a == 1) return 'İç Dağıtım ve Çıkış Raporu'
      return ''
    },
    getSemaUyeInfo(uyeID) {
      const user = this.uyeler.find(item => item.uyeID == uyeID)
      return {
        adSoyad: user && user.adSoyad,
        unvan: user && user.unvan,
      }
    },
    atamaSave() {
      store.dispatch('depoKomisyonSemaKaydet', {
        uyeID: this.atamaData.uye.uyeID,
        pozisyon: this.atamaData.pozisyon.id,
        evrakTuru: this.atamaData.evrakTuru.id,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.isAtama = false
              this.getKomisyonSema()
              this.reset()
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    updateStepSecond() {
      store.dispatch('depoKomisyonUyeDuzenle', {
        uyeID: this.uyeID,
        adSoyad: this.name,
        unvan: this.unvan,
      })
          .then((res, position = 'bottom-right') => {
            this.getUyeler()
            this.reset()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    updateStepOne(item) {
      this.edit = true
      this.uyeID = item.uyeID
      this.name = item.adSoyad
      this.oldName = item.adSoyad
      this.unvan = item.unvan
    },
    remove(uyeID) {
      store.dispatch('depoKomisyonUyeSil', uyeID)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.getUyeler()
              this.reset()
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    removeSema(semaID) {
      store.dispatch('depoKomisyonSemaSil', semaID)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.getKomisyonSema()
              this.reset()
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    save() {
      store.dispatch('depoKomisyonUyeKaydet', {
        adSoyad: this.name,
        unvan: this.unvan,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.isSave = false
              this.edit = false
              this.getUyeler()
              this.reset()
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    getUyeler() {
      store.dispatch('depoUyeListesi')
    },
    getKomisyonSema() {
      store.dispatch('depoKomisyonSemaListesi')
    },
    reset() {
      this.uyeID = null
      this.unvan = null
      this.name = null
      this.edit = false
      this.oldName = null
      this.atamaData.uye = null
      this.atamaData.pozisyon = null
      this.atamaData.evrakTuru = null
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
  beforeDestroy() {
    store.commit('DEPO_UYELER_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.getKomisyonSema()
    this.getUyeler()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
